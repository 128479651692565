import { penceToPounds } from '@punnet/pure-utility-kit'
import { DateBox } from '@punnet/web-client-kit-library'
import { sovereignProductConfigService } from '@sovereign/product-service'
import { For, type Component } from 'solid-js'
import { useClaimActions, useClaimItems } from '../../../../app/store/claims/ClaimsAssessmentStore'
import { GreyButton } from '../../../Button/Buttons'
import styles from './ClaimAssessmentForm.module.css'


export const ClaimAssessmentForm = () => {
    return (
        <div class={styles.ClaimAssessmentForm}>
            <ClaimAssessmentTable />
        </div>
    )
}

const ClaimAssessmentTable = () => {
    const items = useClaimItems()
    const { addItem, removeItem, updateItemLocation, updateItemTreatmentDescription, updateItemTreatmentDate, updateItemCost, updateItemBenefit, updateItemDecision } = useClaimActions()

    return (
        <>
            <table class={styles.ClaimAssessmentTable}>
                {/* for now, these will be hardcoded but we should flex these based on the available assessment items */}
                <thead>
                    <tr>
                        <th>Treatment description</th>
                        <th>Location</th>
                        <th>Treatment date</th>
                        <th>Cost</th>
                        <th>Benefit</th>
                        <th>Decision</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <For each={items}>
                        {(item) => (
                            <tr>
                                <td>
                                    <input 
                                        type="text" 
                                        value={item.treatmentDescription} 
                                        placeholder="Enter description" 
                                        onChange={(e) => updateItemTreatmentDescription(item.id, e.target.value)} 
                                    />
                                </td>
                                <td class={styles.ColumnLocation}>
                                    <input 
                                        type="text" 
                                        value={item.location} 
                                        onChange={(e) => updateItemLocation(item.id, e.target.value)} 
                                        placeholder="Postcode" 
                                    />
                                </td>
                                <td class={styles.ColumnDate}>
                                    <DateBox 
                                        placeholder="dd/mm/yyyy"
                                        selectedDate={item.treatmentDate}
                                        onDateEntered={date => updateItemTreatmentDate(item.id, date)}
                                    />
                                </td>
                                <td class={styles.ColumnCost}>
                                    <input 
                                        type="number" 
                                        value={penceToPounds(item.cost)} 
                                        placeholder="£" 
                                        onChange={(e) => updateItemCost(item.id, e.target.value)} 
                                    />
                                </td>
                                <td class={styles.ColumnBenefit}>
                                    <BenefitSelectList 
                                        selectedItem={item.benefit} 
                                        onSelect={(value) => updateItemBenefit(item.id, value)} 
                                    />
                                </td>
                                <td class={styles.ColumnDecision}>
                                    <DecisionSelectList 
                                        selectedItem={item.decision} 
                                        onSelect={(value) => updateItemDecision(item.id, value)} 
                                    />
                                </td>
                                <td class={styles.ColumnRemove}>
                                    <RemoveItemButton onClick={() => removeItem(item)} />
                                </td>
                            </tr>
                        )}
                    </For>
                </tbody>
            </table>

            <div>
                <GreyButton class={styles.AddItemButton} onClick={addItem}>Add item</GreyButton>
            </div>

            {/* TODO: here for debugging purposes only */}
            {/* <div>
                <For each={items}>
                    {(item) => (
                        <div>
                            {JSON.stringify(item)}
                        </div>
                    )}
                </For>
            </div> */}
        </>
    )
}


type SelectListProps = {
    selectedItem: string
    onSelect: (value: any) => void
}

const BenefitSelectList: Component<SelectListProps> = (props) => {
    const benefitTypes = sovereignProductConfigService.getBenefitTypesWithAnyObligationToClaim().sort()
    const listItems = [
        { value: '', label: '' },
        ...benefitTypes.map(benefitType => ({ value: benefitType, label: benefitType }))
    ]

    return <SelectList items={listItems} selectedItem={props.selectedItem} onSelect={props.onSelect} />
}

const DecisionSelectList: Component<SelectListProps> = (props) => {
    const decisionTypes = [
        { value: '', label: '' },
        { value: 'approved', label: 'Approve' },
        { value: 'rejected', label: 'Deny - general exclusion' }
    ]

    return <SelectList items={decisionTypes} selectedItem={props.selectedItem} onSelect={props.onSelect} />
}

const SelectList: Component<SelectListProps & { items: { value: string, label: string }[] }> = (props) => {
    return <select onChange={(e) => props.onSelect(e.target.value)}>
        <For each={props.items}>
            {(item) => <option value={item.value} selected={item.value === props.selectedItem}>{item.label}</option>}
        </For>
    </select>
}

const RemoveItemButton: Component<{ onClick: () => void }> = (props) => {
    return <span onClick={props.onClick} class={styles.DeleteItemButton}><i class="fas fa-trash"/></span>
}
