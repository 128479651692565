import { createWithStore } from 'solid-zustand'
import type { ClaimAssessmentItem } from '../../../components/Claims/Claim/ClaimAssessmentForm/ClaimAssessmentItem'
import { newUUID } from '@punnet/pure-utility-kit'

type ClaimAssessmentStoreData = {
    items: ClaimAssessmentItem[]
}

type ClaimsAssessmentStore = ClaimAssessmentStoreData & {
    actions: {
        addItem: () => void
        removeItem: (item: ClaimAssessmentItem) => void
        updateItemLocation: (itemId: string, value: any) => void
        updateItemTreatmentDescription: (itemId: string, value: any) => void
        updateItemTreatmentDate: (itemId: string, value: any) => void
        updateItemCost: (itemId: string, value: any) => void
        updateItemBenefit: (itemId: string, value: any) => void
        updateItemDecision: (itemId: string, value: any) => void
    }
}

const emptyClaimAssessmentItem = (): ClaimAssessmentItem => ({
    id: newUUID(),
    treatmentDescription: '',
    location: '',
    treatmentDate: undefined,
    cost: undefined,
    benefit: '',
    decision: ''
})

const updateItems = (itemId: string, updatedField: Partial<ClaimAssessmentItem>) => {
    const allItems = useClaimItems()
    return allItems.map(item => item.id === itemId ? { ...item, ...updatedField } : item)
}

const useStore = createWithStore<ClaimsAssessmentStore>((set, get) => ({
    items: [emptyClaimAssessmentItem()],
    actions: {
        addItem: () => {
            set(state => ({ items: [...state.items, emptyClaimAssessmentItem()] }))
        },
        removeItem: (item: ClaimAssessmentItem) => {
            set(state => ({ items: state.items.filter(i => i.id !== item.id) }))
        },
        updateItemLocation: (itemId: string, value: string) => {
            const updatedField = { location: value }
            set({ items: updateItems(itemId, updatedField) })
        },
        updateItemTreatmentDescription: (itemId: string, value: string) => {
            const updatedField = { treatmentDescription: value }
            set({ items: updateItems(itemId, updatedField) })
        },
        updateItemTreatmentDate: (itemId: string, value: number) => {
            const updatedField = { treatmentDate: value }
            set({ items: updateItems(itemId, updatedField) })
        },
        updateItemCost: (itemId: string, value: string) => {
            const updatedField = { cost: parseInt(value) * 100 }
            set({ items: updateItems(itemId, updatedField) })
        },
        updateItemBenefit: (itemId: string, value: string) => {
            const updatedField = { benefit: value }
            set({ items: updateItems(itemId, updatedField) })
        },
        updateItemDecision: (itemId: string, value: string) => {
            const updatedField = { decision: value }
            set({ items: updateItems(itemId, updatedField) })
        }
    }
}))


export const useClaimItems = () => useStore(state => state.items)
export const useClaimActions = () => useStore(state => state.actions)
