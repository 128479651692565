import type { SubscriptionQuoteRequest, SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import { useSubscriptionService } from '@punnet/subscription-web-client'
import { Modal } from '@punnet/web-client-kit-library'
import { startOfToday } from 'date-fns'
import { createContext, createMemo, useContext, type ParentComponent } from 'solid-js'
import { createSubscription } from '../../../services/quote/createSubscription'
import { persistQuote } from '../../../services/quote/persistQuote'
import { mapToSubscriptionRequest } from '../../../store/quotes/mapToSubscriptionRequest'
import { subscribeToStore, useHasStartDateExpired, useStoreActions, type QuoteAndBuyState } from '../../../store/quotes/quoteAndBuyStore'
import { Quote } from '../Quote'
import { useQuoteResponse } from '../QuoteController/QuoteController'
import sectionStyles from '../Section.module.css'
import styles from './PurchaseQuote.module.css'
import { useSavedSubscriptionQuote } from './SubscriptionQuoteLoader'

type QuotePersister = {
    persist: () => Promise<SubscriptionQuoteResponse>
}

const Context = createContext<QuotePersister>()

export const PurchaseQuote: ParentComponent = (props) => {
    const subscriptionService = useSubscriptionService()
    const quoteResponse = useQuoteResponse()
    const savedQuote = useSavedSubscriptionQuote()
    const getQuoteRequest = () => mapToSubscriptionRequest(useStoreActions.getState(), quoteResponse() ?? savedQuote)

    const quotePersister: QuotePersister = {
        persist: async () => persistQuote(subscriptionService, getQuoteRequest())
    }

    const quoteSubscription = createSubscription<QuoteAndBuyState, SubscriptionQuoteRequest>(
        subscribeToStore,
        (state, prevState) => state.quoteRequestId !== prevState.quoteRequestId,
        (state) => getQuoteRequest() as SubscriptionQuoteRequest
    )

    const handleQuoteResponse = async () => {
        await quotePersister.persist()
    }

    return (
        <div class={sectionStyles.StripeySections}>
            <Context.Provider value={quotePersister}>
                <HandleExpiredStartDate />
                <Quote onQuoteResponse={handleQuoteResponse} subscribe={quoteSubscription}>
                    {props.children}
                </Quote>
            </Context.Provider>
        </div>
    )
}

export const useQuotePersister = () => {
    return useContext(Context)
}


// THIS COMPONENT WILL RENDER IF THE START DATE IS EXPIRED
// this will happen whenever the data is persisted to the store (i.e. on any Next button click or on load of the page)
// we can trigger a modal to the user + a change of start date here to today
const HandleExpiredStartDate = () => {
    const hasStartDateExpired = createMemo(() => useHasStartDateExpired())
    const { setStartDate } = useStoreActions

    return <Modal
        isOpen={hasStartDateExpired()}
        onDismiss={() => setStartDate(startOfToday().getTime())}
        class={styles.Modal}
    >
        <section>
            <h3>Start Date Change</h3>
            <p>Start date has expired!</p>
        </section>
    </Modal>
}