import type { RestrictedRouteDefinition } from '@punnet/web-client-kit-library'
import { Claim } from '../pages/Claims/Claim/Claim'
import { Claims } from '../pages/Claims/Claims'
import { Users } from '../pages/Users/Users'
import { getPermissions } from '../services/permissions/PermissionsController'


const isClaimsUser = () => getPermissions()?.isClaimsUser()

const isUserAdmin = () => getPermissions()?.isBrokerAdmin() || getPermissions()?.isClaimsAdmin() || getPermissions()?.isSuperAdmin()


export const routesBackoffice: RestrictedRouteDefinition[] = [
    {
        path: '/claims',
        component: Claims,
        canAccess: isClaimsUser
    },
    {
        path: '/claims/:id',
        component: Claim,
        canAccess: isClaimsUser
    },
    {
        path: '/users',
        component: Users,
        canAccess: isUserAdmin
    }
]