import { keyLookup } from '@punnet/pure-utility-kit'

export const UserRoleList = [
    'SUPER_ADMIN',
    'CLAIMS_ADMIN',
    'CLAIMS_USER',
    'BROKER_ADMIN',
    'BROKER',
    'COMPANY_ADMIN',
    'FINANCE_USER',
    'PRIMARY_MEMBER',
    'SECONDARY_MEMBER',
    'DEPENDANT_MEMBER',
] as const

export type UserRole = typeof UserRoleList[number]

export const UserRoles = keyLookup<UserRole>()


