//todo-should move these and other generic config level types out of @peachy/repo-domain and into the product/plan config modules
import { RealBenefitLodgementConfig, ClaimStages, Obligations, ProductsConfig, BenefitConfig, RealBenefitClaimLodgementConfig } from '@peachy/repo-domain'
import rawPlanConfig from './sovereign-plan-config.json'
import type * as PlanConfigJson from '@punnet/product-pure'

const SovereignPlanConfig = rawPlanConfig as PlanConfigJson.Config

function mandatoryClaimLodgement(overrides: Partial<RealBenefitClaimLodgementConfig>) {
    const standardMandatoryClaimConfig: RealBenefitClaimLodgementConfig = {
        obligation: Obligations.MANDATORY, 
        //todo-dp is this the correct lodgement window?
        windowInWeeks: 16,
    }
    const mergedConfig: RealBenefitClaimLodgementConfig = {...standardMandatoryClaimConfig, ...overrides}
    const definition: Pick<RealBenefitLodgementConfig, 'CLAIM'> = {
        [ClaimStages.CLAIM]: mergedConfig
    }
    return definition
}

function globalDefinitionParts(type: string): Pick<BenefitConfig, 'type' | 'displayName'> {
    const definition = SovereignPlanConfig.benefits.find(it => it.id === type)
    return {
        type: definition.id,
        displayName: definition.name
    }
}

export const SovereignProductConfig: ProductsConfig = {
    planConfig: SovereignPlanConfig,
    benefits: [
        {
            ...globalDefinitionParts('OPTICAL'),
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaimLodgement({questions: ['when-treated', 'what-is-the-total-cost']})
            }
        },
        {
            ...globalDefinitionParts('DENTAL'),
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaimLodgement({questions: ['when-treated', 'what-is-the-total-cost']})
            }
        },
        {
            ...globalDefinitionParts('PRESCRIPTION'),
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaimLodgement({proxyLevel: 'CHILD'})
            },
            children: [
                {
                    type: 'PRESCRIPTION.PRESCRIPTION',
                    displayName: 'Prescription',
                    pseudo: 'CHILD',
                    lodgement: {
                        CLAIM: { questions: ['what-is-the-total-cost'] }
                    },
                },
                {
                    type: 'PRESCRIPTION.VACCINATION',
                    displayName: 'Vaccination',
                    pseudo: 'CHILD',
                    lodgement: {
                        CLAIM: { questions: ['adoption-date'] }
                    }
                }        
            ]
        },
        {
            ...globalDefinitionParts('PHYSIO'),
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaimLodgement({questions: ['when-treated', 'what-is-the-total-cost']})
            }
        },
        {
            ...globalDefinitionParts('CHIROPODY'),
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaimLodgement({questions: ['when-treated', 'what-is-the-total-cost']})
            }
        },
        {
            ...globalDefinitionParts('ACUPUNCTURE'),
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaimLodgement({questions: ['when-treated', 'what-is-the-total-cost']})
            }
        },
        {
            ...globalDefinitionParts('HEALTH_SCREENING'),
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaimLodgement({questions: ['when-treated', 'what-is-the-total-cost']})
            }
        },
        {
            ...globalDefinitionParts('CONSULTATIONS_DIAGNOSTICS'),
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaimLodgement({questions: ['when-treated', 'what-is-the-total-cost']})
            }
        },
        {
            ...globalDefinitionParts('HOSPITAL_IN_PATIENT'),
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaimLodgement({questions: ['when-admitted', 'when-discharged', 'what-is-the-total-cost']})
            }
        },
        {
            ...globalDefinitionParts('BIRTH_ADOPTION'),
            offerAsUpsell: true,
            limit: {unit: 'PENCE'},
            lodgement: {
                ...mandatoryClaimLodgement({proxyLevel: 'CHILD'})
            },
            children: [
                {
                    type: 'BIRTH_ADOPTION.BIRTH',
                    displayName: 'Birth',
                    pseudo: 'CHILD',
                    lodgement: {
                        CLAIM: { questions: ['childs-dob'] }
                    }
                },
                {
                    type: 'BIRTH_ADOPTION.ADOPTION',
                    displayName: 'Adoption',
                    pseudo: 'CHILD',
                    lodgement: {
                        CLAIM: { questions: ['childs-dob', 'adoption-date'] }
                    }
                }       
            ]
        },
        {
            ...globalDefinitionParts('GP_SERVICE'),
            drivesFeatures: ['BOOK_VGP']
        },
        {
            ...globalDefinitionParts('SOVEREIGN_PERKS'),
            drivesFeatures: ['PERKS']
        },
        {
            ...globalDefinitionParts('HEALTH_WELLBEING'),
        },
        {
            ...globalDefinitionParts('EAP'),
            drivesFeatures: ['ADVICE_AND_COUNSELLING']
        }
    ]
}