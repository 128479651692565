import { UserRoles,roleInClaims,  type UserProfileClaims } from '@punnet/iam-pure'

export class Permissions {

    constructor (private readonly claims: UserProfileClaims) {
        console.log('claims', claims)
    }

    canShareQuote() {
        return this.isBroker()
    }

    canAssignQuote() {
        return this.isBroker()
    }

    canPurchaseQuote() {
        return this.isCompanyAdmin() || this.isFinanceUser()
    }

    canEditEmployeeDetails() {
        return this.isCompanyAdmin()
    }

    canEditEmployeeCounts() {
        return this.isBroker()
    }

    canViewQuotes() {
        return this.isBroker()
    }

    isFinanceUser() {
        return roleInClaims(this.claims, UserRoles['FINANCE_USER'])
    }

    isCompanyAdmin(): boolean {
        return roleInClaims(this.claims, UserRoles['COMPANY_ADMIN'])
    }

    isBroker(): boolean {
        return roleInClaims(this.claims, UserRoles['BROKER'], UserRoles['BROKER_ADMIN'])
    }

    isBrokerAdmin(): boolean {
        console.log('isBrokerAdmin', roleInClaims(this.claims, UserRoles['BROKER_ADMIN']))
        return roleInClaims(this.claims, UserRoles['BROKER_ADMIN'])
    }

    isSuperAdmin(): boolean {
        console.log('isSuperAdmin', roleInClaims(this.claims, UserRoles['SUPER_ADMIN']))
        return roleInClaims(this.claims, UserRoles['SUPER_ADMIN'])
    }

    isClaimsUser() {
        return roleInClaims(this.claims, UserRoles['CLAIMS_ADMIN'], UserRoles['CLAIMS_USER'])
    }

    isClaimsAdmin(): boolean {
        return roleInClaims(this.claims, UserRoles['CLAIMS_ADMIN'])
    }
}
