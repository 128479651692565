import { useIamService } from '@punnet/iam-client'
import { Modal, RestrictedNavigationLink } from '@punnet/web-client-kit-library'
import { createComputed, createSignal } from 'solid-js'
import { Iam } from '../../../../components/Iam/Iam'
import styles from './Header.module.css'
import { Navigation } from './Navigation/Navigation'
import Logo from './sovereign-health-care.svg'

export function Header() {

    const iamService = useIamService()
    const [isModalOpen, setIsModalOpen] = createSignal(true)


    createComputed(() => {
        setIsModalOpen(!iamService.isSignedIn())
    })

    const onDismiss = () => {
        if (iamService.isSignedIn()) {
            setIsModalOpen(false)
        }
    }

    const ManageUser = () => <i tabindex="0" onClick={() => setIsModalOpen(true)} class="fas fa-user-circle"/>

    return (<>
            <header class={styles.Header}>
                <Navigation type="primary">
                    <RestrictedNavigationLink path="/quotes">Quotes</RestrictedNavigationLink>
                    <RestrictedNavigationLink path="/overview">Overview</RestrictedNavigationLink>
                    <RestrictedNavigationLink path="/members">Members</RestrictedNavigationLink>
                    <RestrictedNavigationLink path="/scratch">Scratch</RestrictedNavigationLink>
                    <RestrictedNavigationLink path="/claims">Claims</RestrictedNavigationLink>
                    <RestrictedNavigationLink path="/users">Users</RestrictedNavigationLink>
                </Navigation>
                <Logo class={styles.Logo}/>
                <Navigation type="secondary">
                    <ManageUser/>
                </Navigation>
            </header>
            <Modal enableTransitions={true} isOpen={isModalOpen()} onDismiss={onDismiss}><Iam/></Modal>
    </>)
}