
import {API} from '@aws-amplify/api'

export class ClaimsActivityService {
    constructor(readonly api: typeof API) { }

    async getAllClaims(): Promise<any[]> {
        return this.api.get(apiName, `/claims`, {})
    }
}

const apiName = 'ClaimsActivity'
