import { useIamService } from '@punnet/iam-client'
import type { RestrictedRouteDefinition } from '@punnet/web-client-kit-library'
import { MembersRoutes } from '../pages/Members/Routes'
import { Overview } from '../pages/Overview/Overview'
import { ContactDetails } from '../pages/Quotes/NewQuote/ContactDetails'
import { EditQuote } from '../pages/Quotes/NewQuote/EditQuote'
import { NewQuote } from '../pages/Quotes/NewQuote/NewQuote'
import { PlanDetails } from '../pages/Quotes/NewQuote/PlanDetails'
import { SaveAndClose } from '../pages/Quotes/NewQuote/SaveAndClose'
import { PreviousButton } from '../pages/Quotes/PurchaseQuote/NavigationButtons'
import { DirectDebit } from '../pages/Quotes/PurchaseQuote/non-stp/DirectDebit'
import { DirectDebitSuccess } from '../pages/Quotes/PurchaseQuote/non-stp/DirectDebitSuccess'
import { BackToDirectDebitAuthority } from '../pages/Quotes/PurchaseQuote/non-stp/InviteFinanceContact'
import { PurchaseQuoteInviteFinanceRoutes } from '../pages/Quotes/PurchaseQuote/non-stp/Routes'
import { PurchaseQuoteRoutes } from '../pages/Quotes/PurchaseQuote/stp/Routes'
import { Quotes } from '../pages/Quotes/Quotes'
import { hasActiveSubscription, hasLatestQuote } from '../services/AccountProvider'
import { getPermissions } from '../services/permissions/PermissionsController'


const isSignedIn = () => useIamService().isSignedIn()
const canShareQuote = () => getPermissions().canShareQuote()
const canPurchaseQuote = () => getPermissions().canPurchaseQuote()
const isFinanceUser = () => getPermissions().isFinanceUser()
const isCompanyAdmin = () => getPermissions().isCompanyAdmin()


const editQuoteRoutes = [
    {
        path: '/',
        component: PlanDetails
    },
    {
        path: '/contact',
        component: ContactDetails,
        canAccess: () => isSignedIn() && canShareQuote(),
        navigation: {
            secondary:  SaveAndClose
        }
    }
]

export const routesPortal = [
    {
        path: '/quotes',
        component: Quotes,
        canAccess: () => isSignedIn() && canShareQuote()
    },
    {
        path: '/quotes/new',
        component: NewQuote,
        canAccess: () => isSignedIn() && canShareQuote(),
        navigation: {
            secondary: SaveAndClose
        },
        children: editQuoteRoutes
    },
    {
        path: '/quotes/edit/:quoteId',
        component: EditQuote,
        canAccess: () => isSignedIn() && canShareQuote(),
        navigation: {
            secondary:  SaveAndClose
        },
        children: editQuoteRoutes,
        regex: '^/quotes/edit/[\\w-]+(\\/.*)?'
    },
    // purchase quote: finance user routes
    {
        path: '/quotes/dd-only/:quoteId',
        component: DirectDebit,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasLatestQuote() && isFinanceUser()
    },
    {
        path: '/quotes/dd-only-success',
        component: DirectDebitSuccess,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasLatestQuote() && isFinanceUser()
    },
    // purchase quote: non-stp routes
    {
        path: '/quotes/dd/:quoteId/*',
        component: PurchaseQuoteInviteFinanceRoutes,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasLatestQuote() && isCompanyAdmin(),
        navigation: {
            primary: BackToDirectDebitAuthority
        },
        regex: '^/quotes/dd/[\\w-]+$'
    },
    // purchase quote: stp routes 
    {
        path: '/quotes/:quoteId/*',
        component: PurchaseQuoteRoutes,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasLatestQuote() && isCompanyAdmin(),
        navigation: {
            primary: <PreviousButton/>
        },
        regex: '^/quotes/[\\w-]+(\\/.*)?'
    },
    {
        path: '/overview',
        component: Overview,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasActiveSubscription()
    },
    {
        path: '/members/*',
        component: MembersRoutes,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasActiveSubscription(),
        regex: '^/members(/summary)?$'
    }
] as RestrictedRouteDefinition[] 