import { useSubscriptionService } from '@punnet/subscription-web-client'
import { Show, createSignal } from 'solid-js'
import { getQuoteSubscriptionRequestWithSavedQuote } from '../../../../services/quote/getQuoteSubscriptionRequest'
import { useStoreActions } from '../../../../store/quotes/quoteAndBuyStore'
import { Title } from '../../Quote'
import { FormSection, TextSection } from '../../Section'
import { CompanySchemeHeader } from '../CompanySchemeHeader'
import { NavigationButtons } from '../NavigationButtons'


export const Authorise = () => {
    const [agree, setAgree] = createSignal(false)

    const subscriptionService = useSubscriptionService()
    const { validateStore } = useStoreActions

    const [error, setError] = createSignal('')

    const request = getQuoteSubscriptionRequestWithSavedQuote()

    const onSubmit = async () => {
        setError('')

        // if any data is invalid, we can't proceed
        if (!validateStore()) {
            throw new Error('Cannot proceed')
        }

        try {
            // make a call to the server to activate the subscription!
            const response = await subscriptionService.activateSmeSubscription(request)
            // const response = await new Promise((resolve, reject) => setTimeout(reject, 1000)) //TESTING :)
            console.log('activated!', response)
        } catch (error: any) {
            console.error(error)
            setError(error?.message ?? 'An unknown error occurred')
        }
    }

    return <>
        <TextSection>
            <CompanySchemeHeader />
            <p>Please authorise this purchase and hit the finish button when you're ready!</p>
        </TextSection>

        <FormSection>
            <Title>Authorise:</Title>
            {/* TODO: crude implementation of showing errors for now - refactor! */}
            <Show when={error()}>
                <span style={{ color: 'red' }}>{error()}</span>
            </Show>
            <p>
                By clicking 'Authorise' you are authorising the purchase of this company scheme.
                If you have included finance contact details, this scheme will be finalised once
                they have added the Direct Debit details. Please make sure you read and accept the terms & conditions
            </p>
            <label>
                <input required type="checkbox" name="agree" checked={agree()} onChange={(e) => setAgree(e.target.checked)} />
                I agree to the Sovereign company scheme terms & conditions
            </label>
        </FormSection>

        <TextSection>
            <NavigationButtons nextButtonDisabled={!agree()} nextButtonText="Authorise & finish" onNext={onSubmit} />
        </TextSection>
    </>
}