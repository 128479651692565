import { PlanConfiguration, untrack } from '@punnet/web-client-kit-library'
import { AppConfig } from '@sovereign/product-config'
import { Show } from 'solid-js'
import { usePlanConfig } from '../../app/hooks/usePlanConfig'
import { getPermissions } from '../../app/services/permissions/PermissionsController'
import { createLife } from '../../app/store/quotes/createLife'
import { MANAGE_EMPLOYEES_LIFE_VALIDATION, MANAGE_EMPLOYEES_TOOLTIPS, useManageStoreActions, useManageStoreShowModal } from '../../app/store/quotes/manageEmployeesStore'
import { MIN_POLICIES, usePlans, usePolicies, useShowPlanConfig, useStartDate, useStoreActions } from '../../app/store/quotes/quoteAndBuyStore'
import { PrimaryButton } from '../Button/Buttons'
import { ManageEmployeesModal } from '../ManageEmployees/modal/ManageEmployeesModal'
import { PlanMembership } from './PlanMembership'
import { displayConfig } from './config'
import { createPolicy } from '../../app/store/quotes/createPolicy'
import styles from './PlanBuilder.module.css'

const MAX = AppConfig.getPlanPolicyBounds().max
const MIN = AppConfig.getPlanPolicyBounds().min

export function PlanBuilder() {
    const planConfig = usePlanConfig(), displayConfiguration = displayConfig({ readonly: false })
    const permissions = getPermissions()

    const { addPolicies, removePolicies, setShowPlans } = useStoreActions
    const plans = usePlans, policies = usePolicies

    // TODO: put these two into the store?
    const getCountForPlan = (planId: string) => policies().filter(policy => Object.values(policy.lives).some(life => life.planId === planId)).length
    const isPlanActive = (planId: string) => getCountForPlan(planId) > 0

    const activeClass = styles.planCardActive

    const add = (planId: string, numberOfLives: number = 1) => {
        addPolicies(Array.from({ length: numberOfLives }, () => createPolicy(createLife({ planId: planId, type: 'PRIMARY' }))))
    }

    const remove = (planId: string, numberOfLives: number = 1) => {
        const policiesOnPlan = policies().filter(policy => Object.values(policy.lives).some(life => life.planId === planId))
        if (policiesOnPlan.length > 0) {
            removePolicies(policiesOnPlan.slice(0, numberOfLives))
        }
    }

    return (
        <div class={styles.planBuilder}>
            <PlanConfiguration
                planConfig={planConfig}
                displayConfig={displayConfiguration}
                plans={plans()}
                isPlanActive={isPlanActive}
                class={styles.planConfiguration}
                planActiveClass={activeClass}
                showPlans={useShowPlanConfig()}
                onShowPlans={(showPlan) => setShowPlans(showPlan)}
            />
            <div class={styles.employeeCardContainer}>
                <div class={styles.planActionsContainer}>
                    <ManageEmployees />
                </div>
                <div class={styles.planInfoContainer}>
                    <PlanMembership
                        plans={plans()}
                        policies={policies()}
                        min={MIN}
                        max={MAX}
                        count={getCountForPlan}
                        onAdd={add}
                        onRemove={remove}
                        isPlanActive={isPlanActive}
                        isEditable={permissions.canEditEmployeeCounts()}
                        class={styles.planMembership}
                        planActiveClass={activeClass}
                    />
                </div>
            </div>
        </div>
    )
}


const ManageEmployees = () => {

    // untrack - we dont want to pass in the signals, just the values
    const policies = () => untrack(usePolicies())
    const plans = () => untrack(usePlans())
    const startDate = () => untrack(useStartDate())

    const showModal = () => useManageStoreShowModal()
    const { toggleModal } = useManageStoreActions()

    const permissions = getPermissions()

    return (
        <>
            <Show when={permissions.canEditEmployeeDetails()}>
                <PrimaryButton onClick={toggleModal}>Add employee details</PrimaryButton>

                <ManageEmployeesModal
                    isOpen={showModal()}
                    onDismiss={toggleModal}
                    plans={plans()}
                    policies={policies()}
                    startDate={startDate()}
                    minPolicyCount={MIN_POLICIES}
                    onUpdate={useStoreActions.updatePolicies}
                    lifeValidation={MANAGE_EMPLOYEES_LIFE_VALIDATION}
                    toolTips={MANAGE_EMPLOYEES_TOOLTIPS}
                />
            </Show>
        </>
    )
}