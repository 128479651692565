import { newUUID, type Draft } from '@punnet/pure-utility-kit'
import { type Benefit } from '@punnet/product-client'
import { Company, Plan, Policy, SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import { createWithStore } from 'solid-zustand'
import { usePlanConfig } from '../../hooks/usePlanConfig'
import { AGE_BOUNDS, validateLives } from '../../services/validation/validateLives'
import { createPlans } from '../createPlans'
import { addBenefit } from './addBenefit'
import { findDuplicateEmails } from './findDuplicateEmails'
import { mapFromSubscriptionRequest } from './mapFromSubscriptionRequest'
import { removeBenefit } from './removeBenefit'
import { AppConfig } from '@sovereign/product-config'
import { getPrice } from '../../services/quote/getPrice'
import { isBefore, startOfToday } from 'date-fns'

const planConfig = usePlanConfig(), plansFromConfig = () => createPlans(planConfig)

export const MIN_POLICIES = AppConfig.getTotalPolicyBounds().min

export type QuoteAndBuyStateData = {
    subscriptionId: string
    plans: Plan[]
    policies: Draft<Policy>[]
    company: Company
    startDate: number
    hasStartDateExpired: boolean
    showPlanConfig: boolean
    quoteRequestId: string

    // new quote / edit quote flow
    customerEmailAddress?: string

    // state for purchase quote flow
    allowUpgradePlanLevels?: boolean
    allowAddAdultToPlan?: boolean
    sendWelcomeEmailSchedule?: string
    adminHasDirectDebitAuthority?: boolean
    directDebitDetails?: {
        accountNumber: string
        sortCode: string
        accountName: string
        agreeTerms: boolean
        collectionDate: number // from 1-31
    }
    financeContactEmail?: string
}

export type QuoteAndBuyState = QuoteAndBuyStateData & {
    actions: {
        hydrate: (data: SubscriptionQuoteResponse) => void
        setCompany: (company: Company) => void
        setStartDate: (startDate: number) => void
        addPolicies: (policies: Draft<Policy>[]) => void
        updatePolicies: (policies: Draft<Policy>[]) => void
        removePolicies: (policies: Draft<Policy>[]) => void
        addBenefit: (benefit: Benefit, planId?: string) => void
        removeBenefit: (benefit: Benefit, planId?: string) => void
        requestQuote: () => void
        canQuote: () => boolean
        canSaveQuote: () => boolean
        clear: () => void
        getState: () => QuoteAndBuyStateData
        validateStore: () => boolean
        canShare: () => boolean
        enablePurchaseButton: () => boolean
        validatePolicies: () => boolean
        setCustomerEmailAddress: (emailAddress: string) => void
        setAllowUpgradePlanLevels: (allowUpgradePlanLevels: boolean) => void
        setAllowAddAdultToPlan: (allowAddAdultToPlan: boolean) => void
        setSendWelcomeEmailSchedule: (sendWelcomeEmailSchedule: string) => void
        setAdminHasDirectDebitAuthority: (adminHasDirectDebitAuthority: boolean) => void
        setDirectDebitDetails: (directDebitDetails: {
            accountNumber: string
            sortCode: string
            accountName: string
            agreeTerms: boolean
            collectionDate: number
        }) => void
        setFinanceContactEmail: (email: string) => void
        setShowPlans: (showPlans: boolean) => void
    }
}

// DO NOT EXPORT THIS STORE DIRECTLY

const initialState = (): QuoteAndBuyStateData => ({
    subscriptionId: newUUID(),
    plans: plansFromConfig(),
    policies: [] as Draft<Policy>[],
    company: undefined as Company,
    startDate: Date.now(),
    quoteRequestId: '',
    customerEmailAddress: '',
    financeContactEmail: '',
    hasStartDateExpired: false,
    showPlanConfig: true
})


const useStore = createWithStore<QuoteAndBuyState>((set, get) => {
    const validateStore = () => {
        const hasStartDateExpired = isBefore(get().startDate, startOfToday())
        set({ hasStartDateExpired })

        //currently we only check the start date
        return !hasStartDateExpired
    }

    const persist = (data: any) => {
        set({ ...data })
        validateStore()
    }

    return {
        ...initialState(),
        actions: {
            hydrate: (data: SubscriptionQuoteResponse) => {
                const storeData = mapFromSubscriptionRequest(data)
                persist(storeData)
            },
            setCompany: (company: Company) => {
                persist({ company })
            },
            setStartDate: (startDate: number) => {
                persist({ startDate })
                get().actions.requestQuote()
            },
            addPolicies: (policies: Draft<Policy>[]) => {
                persist({ policies: [...get().policies, ...policies] })
                get().actions.requestQuote()
            },
            updatePolicies: (policies: Draft<Policy>[]) => {
                persist({ policies })
                get().actions.requestQuote()
            },
            removePolicies: (policies: Draft<Policy>[]) => {
                persist({ policies: get().policies.filter(p => !policies.some(policy => policy.id === p.id)) })
                get().actions.requestQuote()
            },
            addBenefit: (benefit: Benefit, planId?: string) => {
                const plans = get().plans
                addBenefit(plans, benefit, planId)
                persist({ plans })
                get().actions.requestQuote()
            },
            removeBenefit: (benefit: Benefit, planId?: string) => {
                const plans = get().plans
                removeBenefit(plans, benefit, planId)
                persist({ plans })
                get().actions.requestQuote()
            },
            requestQuote: () => persist({ quoteRequestId: newUUID() }),
            canQuote: () => hasMinPoliciesCount(),
            canSaveQuote: () => canSaveQuote(),
            clear: () => persist({ ...initialState() }),
            getState: () => get() as QuoteAndBuyStateData,
            canShare: (): boolean => canShare(),
            enablePurchaseButton: (): boolean => enablePurchaseButton(),
            validatePolicies: () => get().policies.every(p => validateLives(get().startDate, AGE_BOUNDS, p)) && !findDuplicateEmails(get().policies).length,
            setCustomerEmailAddress: (emailAddress: string) => persist({ customerEmailAddress: emailAddress }),
            setAllowUpgradePlanLevels: (allowUpgradePlanLevels: boolean) => persist({ allowUpgradePlanLevels }),
            setAllowAddAdultToPlan: (allowAddAdultToPlan: boolean) => persist({ allowAddAdultToPlan }),
            setSendWelcomeEmailSchedule: (sendWelcomeEmailSchedule: string) => persist({ sendWelcomeEmailSchedule }),
            setAdminHasDirectDebitAuthority: (adminHasDirectDebitAuthority: boolean) => persist({ adminHasDirectDebitAuthority }),
            setDirectDebitDetails: (directDebitDetails: {
                accountNumber: string
                sortCode: string
                accountName: string
                agreeTerms: boolean
                collectionDate: number
            }) => persist({ directDebitDetails }),
            setFinanceContactEmail: (email: string) => persist({ financeContactEmail: email }),
            validateStore: () => validateStore(),
            setShowPlans: (showPlans) => set({ showPlanConfig: showPlans })
        }
    } as QuoteAndBuyState
})



// export individually (better performance) - see https://tkdodo.eu/blog/working-with-zustand#prefer-atomic-selectors
export const usePlans = () => useStore(state => state.plans)
export const usePolicies = () => useStore(state => state.policies)
export const useLives = () => useStore(state => state.policies.flatMap(p => Object.values(p.lives)))
export const useCompany = () => useStore().company
export const useStartDate = () => useStore().startDate
export const useHasStartDateExpired = () => useStore().hasStartDateExpired
export const useShowPlanConfig = () => useStore().showPlanConfig
export const useCustomerEmailAddress = () => useStore().customerEmailAddress
export const useFinanceContactEmail = () => useStore().financeContactEmail
// one export for all actions - see https://tkdodo.eu/blog/working-with-zustand#separate-actions-from-state
export const useStoreActions = useStore(state => state.actions)
export const hasDirectDebitDetails = () => !!(useStore().directDebitDetails)

const hasPrice = (): boolean => getPrice() !== undefined
const canSaveQuote = (): boolean => canShare()
const canShare = (): boolean => hasMinPoliciesCount() && !!(useCompany()) && !!(useStartDate()) && !!hasPrice()
const enablePurchaseButton = (): boolean => hasMinPoliciesCount() && !!(useStartDate()) && !!hasPrice()

export const subscribeToStore = useStore.subscribe

const hasMinPoliciesCount = (): boolean => usePolicies().length >= MIN_POLICIES