import { createContext, useContext, type ParentProps } from 'solid-js'

import { makeApiGatewayClient } from '@peachy/core-domain-client'
import { DevUserAdminApiDefinition, type DevUserAdminApi } from '@punnet/subscription-pure'

import { API } from '@aws-amplify/api'
import { Auth } from '@aws-amplify/auth'


const DevUserAdminControllerContext = createContext<DevUserAdminApi>()

export function DevUserAdminController(props: ParentProps) {

    const devUserAdminApi = makeApiGatewayClient(DevUserAdminApiDefinition, API, Auth)

    return (
        <DevUserAdminControllerContext.Provider value={devUserAdminApi}>
            {props.children}
        </DevUserAdminControllerContext.Provider>
    )
}


export function useDevUserAdminService() {
    return useContext(DevUserAdminControllerContext)
}

