import { defineRelationships, PrincipalTypeList, type Relationship, type Resource } from '@punnet/heirarchy-pure'
import { SubscriptionResourceRelationshipTypeList, SubscriptionResourceTypes } from './subscription-resource-types'



export const QuoteRelationshipDefinition = defineRelationships({
    redisKeySpace: 'quote',
    resourceTypes: [SubscriptionResourceTypes.QUOTE],
    relationshipTypes: SubscriptionResourceRelationshipTypeList,
    principalTypes: PrincipalTypeList
})

export type QuoteRelationshipDefinitionType = typeof QuoteRelationshipDefinition
export type QuoteRelationship = Relationship<QuoteRelationshipDefinitionType>

export type QuoteResource = Resource<QuoteRelationshipDefinitionType>
export type QuoteRelationshipType = QuoteRelationshipDefinitionType['relationshipTypes'][number]

