import { Suspense, createResource } from 'solid-js'
import { PageTitle } from '../PageTitle'
import { Pulse } from '../../../components/Pulse/Pulse'
import { ClaimsList } from '../../../components/Claims/ClaimsList/ClaimsList'
import styles from './Claims.module.css'
import { useClaimsActivityService } from '../../services/claims/ClaimsActivityProvider'



export const Claims = () => {

    const claimsActivityService = useClaimsActivityService()

    const [claims] = createResource(async () => claimsActivityService.getAllClaims())

    return (
        <div class={styles.Claims}>
            <div>
                <PageTitle>Claims Queue</PageTitle>
            </div>

            <div>
                <Suspense fallback={<Pulse isShown={true} />}>
                    <ClaimsList claims={claims()} />
                </Suspense>
            </div>
        </div>
    )
}
