import type { PrincipalType } from '@punnet/heirarchy-pure'
import type { UserRole } from './iam-roles'

export class InviteUserRequest {
    email: string
    firstName: string
    lastName: string
}

export class InviteUserResponse {
    id: string
}

export class InviteUserErrorResponse {
    message: string
}


export type CognitoUserAttributes<T = {}> = T & {
    email: string
    email_verified: boolean
    phone_number?: string
    phone_number_verified: boolean
    'custom:content_bucket_path'?: string
    given_name?: string
    family_name?: string
    sub: string
    'cognito:username': string
    'custom:intercom_id'?: string
    'custom:accountId'?: string
    'custom:subscriptionId'?: string
    'custom:policyId'?: string
    'custom:lifeId'?: string
}


export type JwtClaims<T extends CognitoUserAttributes> = T & {
    aud: string
    auth_time: string
    event_id: string
    exp: string
    iat: string
    iss: string
    jti: string
    origin_jti: string
    token_use: 'id' | 'access'
}




export type UserProfile = {
    principalId: string
    principalType: PrincipalType
    firstname: string
    lastname: string
    email: string
    phone?: string
    contentBucketPath?: string
    intercomContactId?: string
    accountId?: string
    subscriptionId?: string
    policyId?: string
    lifeId?: string
    roles: UserRole[]
}

type UserProfileClaim = `userProfile:${keyof UserProfile}`

export type UserProfileClaims = {
    [_k in UserProfileClaim]: string
}

export type DraftProfile<T extends UserProfile> = Omit<T, 'principalId'>


