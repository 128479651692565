import { readableDate } from '@punnet/pure-utility-kit'
import { useNavigate } from '@solidjs/router'
import type { Component } from 'solid-js'
import type { Claim } from './ClaimsList'

export const ClaimRow: Component<{ claim: Claim }> = (props) => {
    const claim = props.claim
    
    const navigate = useNavigate()
    const onClick = () => navigate(`/claims/${claim.id}`)

    return (
        <tr onClick={onClick}>
            <td>{claim.reference}</td>
            <td>{/* first name */}</td>
            <td>{/* last name */}</td>
            <td>{/* policy number */}</td>
            <td>{/* company */}</td>
            <td>{readableDate(claim.dateSubmitted, 'dd MMM yyyy')}</td>
            <td>{claim.benefitType}</td>
            <td>{/* status */}</td>
            <td></td>
        </tr>
    )
}