import type { Principal } from '@punnet/heirarchy-pure'

export type SubscriptionSummaryRecord = {
    accountId: string
    subscriptionId: string
    policyHolder: string
    postcode: string
    startDate: number
    members: number
    premium: number
}


export type SubscriptionSummary = SubscriptionSummaryRecord & {
    ownedBy: Principal[]
    sharedWith: Principal[]
    assignedTo: Principal[]
}


export class SubscriptionSummaryList extends Array<SubscriptionSummary> {}