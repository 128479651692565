import { Show, type ParentComponent } from 'solid-js'
import { ClaimAssessmentForm } from '../../../../components/Claims/Claim/ClaimAssessmentForm/ClaimAssessmentForm'
import { ClaimReferenceOverview } from '../../../../components/Claims/Claim/ClaimReferenceOverview'
import { ClaimStatusOverview } from '../../../../components/Claims/Claim/ClaimStatusOverview'
import { Intercom } from '../../../../components/Claims/Claim/Intercom'
import { PlanOverview } from '../../../../components/Claims/Claim/PlanOverview'
import { PageTitle } from '../../PageTitle'
import styles from './Claim.module.css'


export const Claim = () => {
    return (
        <div class={styles.Claim}>
            <PageTitle>Claim</PageTitle>

            <ClaimSection class={styles.ClaimOverview}>
                <div class={styles.Status}>
                    <ClaimStatusOverview />
                </div>
                <div class={styles.ViewCustomer}>
                    {/* view customer */}
                </div>
                <div class={styles.Reference}>
                    <ClaimReferenceOverview />
                </div>
                <div class={styles.PlanDetails}>
                    <PlanOverview />
                </div>
                <div class={styles.Intercom}>
                    <Intercom />
                </div>
            </ClaimSection>

            <ClaimSection title="Claim details" class={styles.ClaimDetails}>
                <div>
                    <ClaimAssessmentForm />
                </div>
            </ClaimSection>

        </div>
    )
}

const ClaimSection: ParentComponent<{ title?: string, class?: string }> = (props) => {
    return (
        <>
            <Show when={props.title}>
                <h2 class={styles.SectionTitle}>{props.title}</h2>
            </Show>
            <div class={props.class}>
                {props.children}
            </div>
        </>
    )
}