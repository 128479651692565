import {
    defineApiGateway,
} from '@peachy/core-domain-pure'


import type { ApiGatewayClient } from '@peachy/core-domain-client'



export class InviteDevUserRequest {
    firstname: string
    lastname: string
    email: string
    role: 'BROKER_ADMIN' | 'BROKER'
}

export class InviteDevUserResponse {
    id: string
}



export const DevUserAdminApiDefinition = defineApiGateway('DevUserAdminApi').withRoutes({
    createDevUser: {
        method: 'POST',
        path: '/dev/user/admin/create',
        isPublic: false,
        requestType: InviteDevUserRequest,
        responseType: InviteDevUserResponse
    },
})

export type DevUserAdminApi = ApiGatewayClient<typeof DevUserAdminApiDefinition>
