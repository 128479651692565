import { keyLookup } from '@punnet/pure-utility-kit'


export const PrincipalTypeList = [
    'PORTAL_USER',
    'MEMBER',
    'COMPANY',
    'GROUP',
] as const

export type ParentPrincipalType = 'COMPANY' | 'GROUP'

export type PrincipalType = typeof PrincipalTypeList[number]

export const PrincipalTypes = keyLookup<PrincipalType>()


export type Principal<T extends PrincipalType = PrincipalType> = {
    principalId: string
    principalType: T
    principalName?: string
}



export const PrincipalRelationshipList = [
    'IS_MEMBER_OF',
    'CAN_WOBO',
    'CANNOT_WOBO',
]

export type PrincipalRelationship = typeof PrincipalRelationshipList[number]

export const PrincipalRelationshipTypes = keyLookup<PrincipalRelationship>()

