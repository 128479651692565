import { createSignal, type Accessor, type Setter } from 'solid-js'
import { createHasFocusSignal, onEnterKeyWhile } from '@punnet/web-client-kit-library'
import { defaultTrue } from '@punnet/pure-utility-kit'


export type PasswordInputProps = {
    password: Accessor<string>
    setPassword: Setter<string>
    reveal?: Accessor<boolean>
    onEnter?: () => void
    autocomplete?: boolean
    placeholder?: string
    class?: string
    ref?: HTMLInputElement
}



export function PasswordInput(props: PasswordInputProps) {
    const mode = () => props.reveal?.() ? 'text' : 'password'
    // const mode = () => 'text'

    const hasFocus = createHasFocusSignal(() => props.ref)
    onEnterKeyWhile(hasFocus, props.onEnter)

    return <input
        ref={props.ref}
        value={props.password()}
        autocomplete={defaultTrue(props.autocomplete).toString()}
        onInput={(e) => props.setPassword(e.currentTarget.value)}
        placeholder={props.placeholder ?? 'password'}
        type={mode()}
        class={props.class}
    />
}



export function PasswordInputWithReveal(props: PasswordInputProps) {
    const [reveal, setReveal] = createSignal(props.reveal?.() ?? false)
    return (
        <label>
            <PasswordInput {...props} reveal={reveal} />
            <input type="checkbox" onClick={() => setReveal(!reveal())} />
            <i></i>
        </label>
    )

}
