import type { Component } from 'solid-js'
import { For } from 'solid-js'
import { ListTable } from '../../ListTable/ListTable'
import { ClaimRow } from './ClaimRow'   

/// for now we're putting the Claim model here but it should be in the backend (this is just a copy of ClaimsActivityRecord)
export type Claim = {
    accountId: string
    subscriptionId: string
    policyId: string
    id: string
    reference: string
    dateSubmitted: number
    treatmentReceiverId: string
    treatmentDate?: number
    symptomsOnsetDate?: number
    costInPence?: number
    benefitType: string
    subrogation?: string
}

type ClaimsListProps = {
    claims: Claim[]
}

const headers = [
    'Claim reference',
    'First name',
    'Surname',
    'Policy number',
    'Company',
    'Submitted date',
    'Benefit',
    'Claim status',
    'Open time'
]

export const ClaimsList: Component<ClaimsListProps> = (props) => {
    return (
        <ListTable headers={headers}>
            <For each={props.claims}>
                {(claim) => <ClaimRow claim={claim} />}
            </For>
        </ListTable>
    )
}