import { createSignal } from 'solid-js'
import { useIamService } from '../IamController'
import { IamError } from '../IamError'
import { PasswordInput, PasswordInputWithReveal } from '../PasswordInput'
import { hasCredentials, type IamEmailSignal } from '../iam-kit'


export function SignUp(props: IamEmailSignal) {
    const iamService = useIamService()

    const [password, setPassword] = createSignal('')

    const signUp = () => {
        if (hasCredentials(props.email, password)) iamService.signUp(props.email(), password())
    }


    const onCancel = () => iamService.cancelSignUp()
        
    return <>
        <h3>Sign Up - DEV CAREFUL!</h3>
        <IamError/>
        <input type="email" value={props.email()} onInput={(e) => props.setEmail(e.currentTarget.value)} placeholder={'email address'}/>
        <PasswordInputWithReveal password={password} setPassword={setPassword} onEnter={signUp} />
        <button onClick={signUp} disabled={!hasCredentials(props.email, password)}>Sign up</button>
        <a onClick={onCancel} tabindex={0}>Cancel &rarr; </a>
    </>
}

