import { Show, createContext, createResource, useContext, type ParentProps } from 'solid-js'
import { ClaimsActivityService } from '@punnet/claims-activity-client'
import { API } from '@aws-amplify/api'
import { Auth } from '@aws-amplify/auth'
import { configureApiGateway } from '@peachy/core-domain-client'


const ClaimsActivityProviderContext = createContext<ClaimsActivityService>()

async function loadClaimsActivityService() {
    await configureApiGateway('ClaimsActivity', API, {auth: Auth})
    return new ClaimsActivityService(API)
}

export function ClaimsActivityProvider(props: ParentProps) {
    const [claimsActivityService] = createResource(loadClaimsActivityService)

    return (
        <Show when={!claimsActivityService.loading}>
            <ClaimsActivityProviderContext.Provider value={claimsActivityService()}>
                {props.children}
            </ClaimsActivityProviderContext.Provider>
        </Show>
    )
}

export function useClaimsActivityService() {
    return useContext(ClaimsActivityProviderContext)
}
