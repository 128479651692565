import { keyLookup } from '@punnet/pure-utility-kit'



export const SubscriptionResourceRelationshipTypeList = [
    'IS_SHARED_WITH',
    'IS_ASSIGNED_TO',
    'IS_OWNED_BY'
] as const

export type SubscriptionResourceRelationshipType = typeof SubscriptionResourceRelationshipTypeList[number]

export const SubscriptionResourceRelationshipTypes = keyLookup<SubscriptionResourceRelationshipType>()


export const SubscriptionResourceTypeList = [
    'SUBSCRIPTION',
    'QUOTE',
    'CLAIMS_ACTIVITY'
] as const

export type SubscriptionResourceType = typeof SubscriptionResourceTypeList[number]  

export const SubscriptionResourceTypes = keyLookup<SubscriptionResourceType>()
