
import styles from './ClaimStatusOverview.module.css'

export const ClaimStatusOverview = () => {
    return (
        <div class={styles.ClaimStatus}>
            <div>Status [Open]</div>
            <div>Name [John Smith]</div>
        </div>
    )
}