import { isValidEmail } from '@punnet/iam-client'
import { Pulse } from '@punnet/web-client-kit-library'
import { createSignal, Show } from 'solid-js'
import styles from './CreateUser.module.css'
import { useDevUserAdminService } from '@punnet/subscription-web-client'



type BrokerRole = 'BROKER_ADMIN' | 'BROKER'


type CreatedUser = {
    id: string
    email: string
    firstname: string
    lastname: string
    role: BrokerRole
}



export function CreateUser() {

    const devUserAdminService = useDevUserAdminService()
    
    const [email, setEmail] = createSignal<string>('')
    const [firstname, setFirstname] = createSignal<string>('')
    const [lastname, setLastname] = createSignal<string>('')

    const [error, setError] = createSignal('')

    const [role, setRole] = createSignal<BrokerRole>('BROKER')
    
    const [isCreatingUser, setIsCreatingUser] = createSignal(false)
    const isValidUser = () => isValidEmail(email()) && firstname() && lastname() && role()
    
    const [createdUser, setCreatedUser] = createSignal<CreatedUser>()

    const createUser = async () => {
        setCreatedUser(undefined)
        if (isValidUser()) {
            setIsCreatingUser(true)
            try {
                const user = await devUserAdminService.createDevUser({
                    email: email(),
                    firstname: firstname(),
                    lastname: lastname(),
                    role: role()
                })
                setCreatedUser({
                    id: user.id,
                    email: email(),
                    firstname: firstname(),
                    lastname: lastname(),
                    role: role()
                })
                setEmail('')
                setFirstname('')
                setLastname('')
                setRole('BROKER')
            
            } catch (e) {
                console.error(e)
                setError(e.toString())
            } finally {
                setIsCreatingUser(false)
            }
        }
    }

    return (
        <div class={styles.CreateUser}>
            <h3>Create User - UAT</h3>
            <input type="email" value={email()} onInput={(e) => setEmail(e.currentTarget.value)} placeholder={'email address'}/>
            <input type="text" value={firstname()} onInput={(e) => setFirstname(e.currentTarget.value)} placeholder={'first name'}/>
            <input type="text" value={lastname()} onInput={(e) => setLastname(e.currentTarget.value)} placeholder={'last name'}/>
            <select value={role()} onChange={(e) => setRole(e.currentTarget.value as BrokerRole)}>
                <option value="BROKER">BDM</option>
                <option value="BROKER_ADMIN">BDM-ADMIN</option>
            </select>
            <button onClick={createUser} disabled={!isValidUser()}>Create User</button>
            <Show when={error()}>
                <p class={styles.Error}>{error()}</p>
            </Show>
            <Show when={createdUser()}>
                <div class={styles.UserCreated}>
                    <h3>User Created:</h3>
                    <ul>
                        <li>ID: <b>{createdUser().id}</b></li>
                        <li>Email: <b>{createdUser().email}</b></li>
                        <li>First Name: <b>{createdUser().firstname}</b></li>
                        <li>Last Name: <b>{createdUser().lastname}</b></li>
                        <li>Role: <b>{createdUser().role}</b></li>
                    </ul>
                </div>
            </Show>
            <Pulse isShown={isCreatingUser()} />
        </div>
    )
}






