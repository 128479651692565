import { Title } from '../../Quote'
import { NavigationButtons } from '../NavigationButtons'
import { FormSection, TextSection } from '../../Section'
import { CompanySchemeHeader } from '../CompanySchemeHeader'
import { createSignal } from 'solid-js'
import { useStoreActions } from '../../../../store/quotes/quoteAndBuyStore'
import { useNavigate, useParams } from '@solidjs/router'
import { useQuotePersister } from '../PurchaseQuote'
import styles from '../../Quote.module.css'


export const DirectDebitAuthority = () => {
    const state = useStoreActions.getState()
    const quotePersister = useQuotePersister()

    const navigate = useNavigate()
    const params = useParams()

    const [authority, setAuthority] = createSignal<boolean | undefined>(state.adminHasDirectDebitAuthority)

    const isValid = () => authority() !== undefined

    const onNext = async () => {
        useStoreActions.setAdminHasDirectDebitAuthority(authority())
        await quotePersister.persist()
        if (authority() === false) {
            navigate(`/quotes/dd/${params.quoteId}`)
        }
    }

    return <>
        <TextSection>
            <CompanySchemeHeader />
            <p>Add your company direct debit details.</p>
            <p>You can add details for a finance contact to add Direct Debit details if you do not have direct debit authority.</p>
        </TextSection>
        
        <FormSection>
            <Title>Payment:</Title>

            <div class={styles.Section}>
                <p>Do you have Direct Debit authority?</p>
                <label>
                    <input required type="radio" name="directDebitAuthority" value={'yes'} checked={authority() === true} onChange={() => setAuthority(true)}/>
                    Yes
                </label>
                <label>
                    <input required type="radio" name="directDebitAuthority" value={'no'} checked={authority() === false} onChange={() => setAuthority(false)}/>
                    No
                </label>
            </div>
        </FormSection>

        <TextSection>
            <NavigationButtons nextButtonDisabled={!isValid()} onNext={onNext} />
        </TextSection>
    </>
}