import { defineRelationships, PrincipalTypeList, type Relationship, type Resource } from '@punnet/heirarchy-pure'
import { SubscriptionResourceRelationshipTypeList, SubscriptionResourceTypes } from './subscription-resource-types'

export const SubscriptionRelationshipDefinition = defineRelationships({
    redisKeySpace: 'subscription',
    resourceTypes: [SubscriptionResourceTypes.SUBSCRIPTION],
    relationshipTypes: SubscriptionResourceRelationshipTypeList,
    principalTypes: PrincipalTypeList
})

export type SubscriptionRelationshipDefinitionType = typeof SubscriptionRelationshipDefinition
export type SubscriptionRelationship = Relationship<SubscriptionRelationshipDefinitionType>

export type SubscriptionResource = Resource<SubscriptionRelationshipDefinitionType>
export type SubscriptionRelationshipType = SubscriptionRelationshipDefinitionType['relationshipTypes'][number]

