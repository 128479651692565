import type { Principal } from '@punnet/heirarchy-pure'

export type QuoteStatus = 'DRAFT' | 'SHARED' | 'READY-TO-ACTIVATE'


export type QuoteSummaryRecord = {
    quoteId: string
    policyHolder: string
    postcode: string
    startDate: number
    expiryDate: number
    quoteDate: number
    members: number
    premium: number
}



export type QuoteSummary = QuoteSummaryRecord & {
    ownedBy: Principal[]
    sharedWith: Principal[]
    assignedTo: Principal[]
}


export class QuoteSummaryList extends Array<QuoteSummary> {}
