
import { IamController } from '@punnet/iam-client'
import { SubscriptionController } from '@punnet/subscription-web-client'
import { KeyboardController, RestrictedRouteProvider, dynamicCssKeyboardModifierClasses, dynamicCssPageScrollTop, useRestrictedRoutes, type RestrictedRouteDefinition } from '@punnet/web-client-kit-library'
import { Router } from "@solidjs/router"
import { Show } from 'solid-js'
import { MainLayout } from './layouts/main-layout/MainLayout'
import { routesAll } from './routes/routes-all'
import { routesBackoffice } from './routes/routes-backoffice'
import { routesPortal } from './routes/routes-portal'
import { AccountProvider } from './services/AccountProvider'
import { AuthenticationController } from './services/authentication/AuthenticationController'
import { PermissionsController, getPermissions } from './services/permissions/PermissionsController'
import { ClaimsActivityProvider } from './services/claims/ClaimsActivityProvider'


export function App() {
    dynamicCssKeyboardModifierClasses()
    dynamicCssPageScrollTop()
    return (
        <KeyboardController>
            <IamController iamConfigType={'PortalIamConfig'}>
                <SubscriptionController>
                    <AuthenticationController>
                        <PermissionsController>
                            <Show when={getPermissions()?.isClaimsUser() || getPermissions()?.isSuperAdmin()} fallback={<PortalApp />}>
                                <BackOfficeApp />
                            </Show>
                        </PermissionsController>
                    </AuthenticationController>
                </SubscriptionController>
            </IamController>
        </KeyboardController>
    )
}

function ActualApp() {
    return (
        <Router root={MainLayout}>
            {useRestrictedRoutes()}
        </Router>
    )
}

function BackOfficeApp () {
    const routes: RestrictedRouteDefinition[] = [...routesAll, ...routesBackoffice]
    
    return (
        <ClaimsActivityProvider>
            <RestrictedRouteProvider routes={routes}>
                <ActualApp />
            </RestrictedRouteProvider>
         </ClaimsActivityProvider>
    )
}

function PortalApp () {
    const routes: RestrictedRouteDefinition[] = [...routesAll, ...routesPortal]

    return (
        <AccountProvider>
            <RestrictedRouteProvider routes={routes}>
                <ActualApp />
            </RestrictedRouteProvider>
        </AccountProvider>
    )
}


