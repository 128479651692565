import { Show, createSignal, type ParentComponent } from 'solid-js'
import { hasNextPage, nextPage } from './PageRouter'
import { Pulse } from '../Pulse/Pulse'

export interface NextProps {
    onClick?: (event: any) => Promise<void>
}

export const Next: ParentComponent<NextProps> = (props) => {
    const [isLoading, setIsLoading] = createSignal(false)

    const onClick = async (event: any) => {
        setIsLoading(true)
        try {
            props.onClick && await props.onClick?.(event)
            nextPage()
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Show when={hasNextPage()} fallback={<span />}>
                <div id="next" onClick={onClick}>{props.children}</div>
            </Show>
            <Show when={isLoading()}>
                <Pulse isShown={true} />
            </Show>
        </>
    )
}
