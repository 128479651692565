import { DevUserAdminController } from '@punnet/subscription-web-client'
import { CreateUser } from './CreateUser'


export function Users() {
    return (
        <div>
            <DevUserAdminController>
                <CreateUser />
            </DevUserAdminController>
        </div>
    )
}